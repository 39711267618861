const AppRoutes = {
  login: "login",
  mainView: "",
  resetPassword: "resetPassword/:token",
} as const;

const MainViewRoutes = {
  userProfile: "profile",
  randomProfile: "profile/:id",
  animalProfile: "animalProfile/:id",
  animalMapTest: "animalMappTest",
  animalOverview: `animalOverview`,
  animalMarket: `animalMarket`,
  animalForm: "animalForm",
  animalMap: "animalMap",
  notifications: `notifications`,
  createPost: `createPost`,
  messages: `messages`,
  singleMessage: `messages/:id`,
  startChat: "startChat",
  singlePost: `posts/:id`,
  start: "",
  group: "group",
  event: "event",
  animalFriendly: "animalFriendly",
  training: "training",
} as const;

const UserProfileRoutes = {
  userSettings: `userSettings`,
  posts: `posts`,
  media: `media`,
  friends: `friends`,
  register: `register`,
  update: `update`,
  myEvent: "myEvent",
  myGroup: "myGroup",
  myTraining: "myTraining",
  myAnimalFriendly: "myAnimalFriendly",
} as const;

const AnimalProfileRoutes = {
  events: `events`,
  training: `training`,
  family: `family`,
  care: `care`,
  edit: `edit`
} as const;

const ExternalUserRoutes = {
  posts: "posts",
  friends: "friends",
} as const;

const AppPathes = (
  route: typeof AppRoutes[keyof typeof AppRoutes],
  id?: string
): string => {
  if (id) return route.replace(":id", id);
  return route;
};

const MainViewPathes = (
  route: typeof MainViewRoutes[keyof typeof MainViewRoutes],
  id?: string
): string => {
  if (id) return AppRoutes.mainView + "/" + route.replace(":id", id);
  return AppRoutes.mainView + "/" + route;
};

const UserProfilePathes = (
  route: typeof UserProfileRoutes[keyof typeof UserProfileRoutes],
  id?: string
) => {
  if (id)
    return (
      AppRoutes.mainView +
      MainViewRoutes.userProfile +
      "/" +
      route.replace(":id", id)
    );
  return AppRoutes.mainView + "/" + MainViewRoutes.userProfile + "/" + route;
};

function AnimalProfilePathes(
  route: typeof AnimalProfileRoutes[keyof typeof AnimalProfileRoutes],
  id: string | string[]
): string {
  let ids = typeof id == "string" ? [id] : id;
  const path =
    AppRoutes.mainView + "/" + MainViewRoutes.animalProfile + "/" + route;

  const array = path.split("/");
  let index = array.findIndex((el) => el == ":id");
  while (index > -1) {
    const id = ids.shift();
    if (id) {
      array.splice(index, 1, id);
    }
    index = array.findIndex((el) => el == ":id");
  }
  let finalPath = array.join("/");


  console.log('final path: ', finalPath);
  return finalPath;

}

function ExternalUserPath(
  route: typeof ExternalUserRoutes[keyof typeof ExternalUserRoutes],
  id: string
) {
  return (
    AppRoutes.mainView +
    "/" +
    MainViewRoutes.randomProfile.replace(":id", id) +
    "/" +
    route
  );
}

export const PATHES = {
  resetPassword: AppPathes(AppRoutes.resetPassword),
  login: AppPathes(AppRoutes.login),
  animalProfile: (id: string) =>
    MainViewPathes(MainViewRoutes.animalProfile, id),

  randomProfilePosts: (id: string) =>
    ExternalUserPath(ExternalUserRoutes.posts, id),
  randomProfileFriends: (id: string) =>
    ExternalUserPath(ExternalUserRoutes.friends, id),

  start: MainViewPathes(MainViewRoutes.start),
  messages: MainViewPathes(MainViewRoutes.messages),
  singleMessage: (id: string) =>
    MainViewPathes(MainViewRoutes.singleMessage, id),
  startChat: MainViewPathes(MainViewRoutes.startChat),
  singlePost: (id: string) => MainViewPathes(MainViewRoutes.singlePost, id),
  createPost: MainViewPathes(MainViewRoutes.createPost),
  notifications: MainViewPathes(MainViewRoutes.notifications),
  event: MainViewPathes(MainViewRoutes.event),
  group: MainViewPathes(MainViewRoutes.group),
  training: MainViewPathes(MainViewRoutes.training),
  animalFriendly: MainViewPathes(MainViewRoutes.animalFriendly),

  userProfile: UserProfilePathes(UserProfileRoutes.posts),
  userSettings: UserProfilePathes(UserProfileRoutes.userSettings),
  myEvent: UserProfilePathes(UserProfileRoutes.myEvent),
  myGroup: UserProfilePathes(UserProfileRoutes.myGroup),
  myTraining: UserProfilePathes(UserProfileRoutes.myTraining),
  myAnimalFriendly: UserProfilePathes(UserProfileRoutes.myAnimalFriendly),
  userPosts: UserProfilePathes(UserProfileRoutes.posts),
  userMedia: UserProfilePathes(UserProfileRoutes.media),
  userFriends: UserProfilePathes(UserProfileRoutes.friends),
  registerAnimal: UserProfilePathes(UserProfileRoutes.register),
  updateUser: UserProfilePathes(UserProfileRoutes.update),

  animalEvents: (id: string) =>
    AnimalProfilePathes(AnimalProfileRoutes.events, id),

  animalFamily: (id: string) =>
    AnimalProfilePathes(AnimalProfileRoutes.family, id),

  animalTraining: (id: string) =>
    AnimalProfilePathes(AnimalProfileRoutes.training, id),

  animalCare: (id: string) => 
    AnimalProfilePathes(AnimalProfileRoutes.care, id),

  animalEdit: (id: string) => 
    AnimalProfilePathes(AnimalProfileRoutes.edit, id),
};

export default {
  AppRoutes,
  MainViewRoutes,
  UserProfileRoutes,
  AnimalProfileRoutes,
  RandomProfileRoutes: ExternalUserRoutes,
};
