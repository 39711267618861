import { Injectable } from "@angular/core";
import { Observable, BehaviorSubject, AsyncSubject, tap, throwError } from "rxjs";
import { ApiService } from "../../shared/service/http.service";
import { environment } from "src/environments/environment";
import { Animal } from "src/app/shared/model/animal";
import { FamilyMember } from "src/app/shared/model/family-member";
import { RegisterAnimal } from "src/app/shared/model/register-animal.model";
import { Media } from "src/app/shared/model/posts/postMedia";
import { GalleryImage } from "src/app/shared/types/GalleryImage";

interface FamilyMemberIn {
  id: number;
  gender: string;
  regnr: string;
  colors: string | null;
  titles: string | null;
  hundnamn: string;
}
interface FamilyMemberOut {
  id: number;
  gender: string;
  regnr: string;
  colors: string | null;
  titles: string | null;
  hundnamn: string;
  position: number;
  childPosition: number;
  generation: number;
  parents?: FamilyMemberOut[];
}
interface ArraysObject {
  [key: string]: FamilyMemberIn[];
}
@Injectable({
  providedIn: "root",
})
export class AnimalProfileService {
  // private missionAnnouncedSource = new Subject<int>();
  // Observable string sources
  // Observable string sources
  private animalIdSource = new BehaviorSubject("");
  // Observable string streams
  currentAnimalId = this.animalIdSource.asObservable();

  /* private animalIdSource: BehaviorSubject<number>= new BehaviorSubject<number>(0);
public currentAnimalId: Observable<number> = this.animalIdSource.asObservable(); */

  private baseUrl = environment.BaseUrl;

  constructor(private _httpService: ApiService) {}


  private animalsSource: BehaviorSubject<Animal[]> = new BehaviorSubject<Animal[]>([]);
  public currentAnimals: Observable<Animal[]> = this.animalsSource.asObservable();

  private allAnimalsSource: BehaviorSubject<Animal[]> = new BehaviorSubject<Animal[]>([]);
  public allCurrentAnimals: Observable<Animal[]> = this.allAnimalsSource.asObservable();

  private animalsTypesSource: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
  public currentTypes: Observable<string[]> = this.animalsTypesSource.asObservable();

  private animalsBreedsSource: BehaviorSubject<string[]> = new BehaviorSubject<
    string[]
  >([]);
  public currentBreeds: Observable<string[]> =
    this.animalsBreedsSource.asObservable();

  private animalsGendersSource: BehaviorSubject<string[]> = new BehaviorSubject<
    string[]
  >([]);
  public currentGenders: Observable<string[]> =
    this.animalsGendersSource.asObservable();

  private dogFamilyTree = new AsyncSubject();
  setDogFamilyTree(dogFamily: FamilyMember) {
    this.dogFamilyTree.next(dogFamily);
    this.dogFamilyTree.complete();
  }
  getDogFamilyTree(): Observable<any> {
    return this.dogFamilyTree.asObservable();
  }

  saveAnimalProfile(animal: Animal): Observable<Animal> {
    //const url = `${this.baseUrl}/SaveAnimal`;
    //const updatedAnimal = this.genericHttpService.post<Animal>(url, animal);

    // when the result is returned from the server, update any listener to profile$.
    // updatedAnimal.subscribe({
    //   next: (updated) => this.animalSource.next(updated)
    // });

    const url = environment.registerAnimal;

    const queryParams = { model: animal };

    const updatedAnimal = this._httpService.Post<Animal>(url, queryParams);

    return updatedAnimal;
  }

  registerAnimal(animal: RegisterAnimal): Observable<Animal> {
    const url = environment.registerAnimal;

    const queryParams = { model: animal };
    console.log("animal: ", animal);

    const updatedAnimal = this._httpService.Post<Animal>(url, queryParams);

    return updatedAnimal;
  }

  updateAnimal(updatedAnimal: Animal): Observable<Animal> {
    const animals = this.animalsSource.getValue();
    const animalIndex = animals.findIndex((animal) => animal.id === updatedAnimal.id);
    console.log("Updated animal");

    if (animalIndex !== -1) {
      
      animals[animalIndex] = updatedAnimal;
      this.animalsSource.next(animals);

      const url = environment.registerAnimal;

      console.log("animal: ", updatedAnimal);

      
      
      const updatePayload = {
        id: updatedAnimal.id,
        name: updatedAnimal.name ?? null,
        birthday: updatedAnimal.dateOfBirth ? updatedAnimal.dateOfBirth.toISOString() : null, // Match register dto
        gender: updatedAnimal.gender ?? null,
        breed: updatedAnimal.breed ?? null,
        chipNumber: updatedAnimal.chipNumber ?? null,
      };


      
      return this._httpService.Put<Animal>(url, { data: updatePayload });
    }

    return throwError(()=>new Error(`Animal with ID ${updatedAnimal.id} not found`));
  }



  DeleteAnimal(AnimalId: number): Observable<any> {
    
    const animals = this.animalsSource.getValue();

    
    const animalIndex = animals.findIndex((animal) => animal.id === AnimalId);

    if (animalIndex !== -1) {
      
      animals.splice(animalIndex, 1);

      
      this.animalsSource.next(animals);

      
      const url = environment.registerAnimal;
      const urlWithId = `${url}/${AnimalId}`;

     
      return this._httpService.Delete(urlWithId);
    }

    
    return throwError(()=>new Error(`Animal with ID ${AnimalId} not found`));
  }


  public setAnimals(Animals: Animal[]) {
    this.animalsSource.next(Animals);
  }

  public getAnimals() {
    return this.animalsSource.value;
  }

  getUserAnimals(ownerId?: number) {
    this._httpService
      .Get<[]>(`${environment.userBaseURL}/Animals`, {
        params: { id: ownerId },
      })
      .subscribe((res) => {
        if (res) {
          this.setAnimals(res);
        }
      });
  }

  getAllAnimals() {
    const url = `${this.baseUrl}/Animal/All`;

    this._httpService.Get<[]>(url).subscribe((res) => {
      if (res) {
        this.setallAnimals(res);
      }
    });
  }

  public setallAnimals(Animals: Animal[]) {
    this.allAnimalsSource.next(Animals);
  }

  public getallAnimals() {
    return this.allAnimalsSource.value;
  }

  getAnimalGenders() {
    const url = `${this.baseUrl}/getAnimalGenders`;

    this._httpService.Get<[]>(url).subscribe((res) => {
      if (res) {
        this.setAnimalsGenders(res);
      }
    });
  }

  public setAnimalsGenders(animalsGenders: string[]) {
    this.animalsGendersSource.next(animalsGenders);
  }

  public getAnimalsGenders() {
    return this.animalsGendersSource.value;
  }

  public existGenders() {
    if (this.animalsBreedsSource.value.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  getAnimalBreeds() {
    const url = `${this.baseUrl}/getAnimalBreeds`;

    this._httpService.Get<[]>(url).subscribe((res) => {
      if (res) {
        this.setAnimalsBreeds(res);
      }
    });
  }

  public setAnimalsBreeds(animalsBreeds: string[]) {
    this.animalsBreedsSource.next(animalsBreeds);
  }

  public getAnimalsBreeds() {
    return this.animalsBreedsSource.value;
  }

  public existBreeds() {
    if (this.animalsBreedsSource.value.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  setAnimalId(animalId: string) {
    this.animalIdSource.next(animalId);
  }

  getAnimalTypes() {
    const url = `${this.baseUrl}/getAnimalTypes`;

    this._httpService.Get<[]>(url).subscribe((res) => {
      if (res) {
        this.setAnimalsTypes(res);
      }
    });
  }

  public setAnimalsTypes(animalsTypes: string[]) {
    this.animalsTypesSource.next(animalsTypes);
  }

  public getAnimalsTypes() {
    return this.animalsTypesSource.value;
  }

  public exictTypes() {
    if (this.animalsTypesSource.value.length > 0) {
      return true;
    } else {
      return false;
    }
  }
  public existAnimalId() {
    let animalId = "";
    this.currentAnimalId;
    if (Number(this.currentAnimalId) == 0) {
      return false;
    } else {
      return true;
    }
  }



  getAnimalFamilyTree(id: number): Observable<any> {
  const url = environment.getAnimalFamilyTree;
   const queryParams = {
     id: id,
    };
    return this._httpService.Get<any>(url, { params: queryParams } );
  }

  registerDog(registerAnimal: RegisterAnimal): Observable<any> {
    console.log("registerAnimal: ", registerAnimal);
    return this._httpService.Post(environment.registerDog, registerAnimal);
  }

  getFamilyGenerations(num: number): number {
    const nestedNum = 2;
    let levels = 1;
    let newNum = num - 1;
    let multiplier = 1;
    while (newNum > 0) {
      newNum = newNum - nestedNum * multiplier;
      multiplier = multiplier * nestedNum;
      levels++;
    }
    return levels;
  }
  sliceArrayInHalf(arr: any) {
    if (arr.length % 2 == 0) {
      const half1 = arr.slice(0, arr.length / 2);
      const half2 = arr.slice(arr.length / 2, arr.length);
      return [half1, half2];
    } else {
      return 'error cann"t slice in half';
    }
  }

  combineAnimalFamilies(arr: FamilyMemberIn[]) {
    const genrations = this.getFamilyGenerations(arr.length);
    const families: FamilyMemberOut[] = [];
    families.push({
      ...arr.shift()!,
      generation: 1,
      position: 1,
      childPosition: 0,
      parents: [],
    });

    // In Case arr has only child and parents
    if (arr.length == 2) {
      for (let index in arr) {
        families.push({
          ...arr[index],
          generation: 2,
          position: Number(index) + 1,
          childPosition: 1,
        });
      }
    } else {
      let arrays: ArraysObject = {};
      for (let gen = 2; gen <= genrations; gen++) {
        if (gen == genrations) {
          // Connect last parents to their children
          let position = 1;
          for (let [key, value] of Object.entries(arrays)) {
            for (let j = 0; j < 2; j++) {
              const name = `${position}`;
              families.push({
                ...value.shift()!,
                generation: gen,
                position: Number(`${position}`),
                childPosition: Number(`${key}`),
              });
              position++;
            }
          }
        } else if (gen == 2) {
          let position = 1;
          const arrs = this.sliceArrayInHalf(arr);
          for (let j = 0; j < arrs.length; j++) {
            const name = `${position}`;
            families.push({
              ...arrs[j].shift(),
              generation: gen,
              position: Number(`${position}`),
              childPosition: Number(gen - 1),
              parents: [],
            });
            position++;
            arrays[name] = arrs[j];
          }
        } else {
          const savedArrays = { ...arrays };
          arrays = {};
          let position = 1;
          for (let [key, value] of Object.entries(savedArrays)) {
            const arrs = this.sliceArrayInHalf(value);
            for (let j = 0; j < arrs.length; j++) {
              console.log(arrs[j]);
              const name = `${position}`;
              families.push({
                ...arrs[j].shift(),
                generation: gen,
                position: Number(`${position}`),
                childPosition: Number(`${key}`),
                parents: [],
              });
              position++;
              arrays[name] = arrs[j];
            }
          }
        }
      }
    }
    families.sort((a, b) => b.generation - a.generation);
    for (let i = 0; i < families.length; i++) {
      const parent = families!.find(
        (obj) =>
          obj!.position == families[i]?.childPosition &&
          obj!.generation == families[i]?.generation - 1
      );
      if (parent) {
        parent.parents?.push(families[i]);
      }
    }
    const dogTree = families[families.length - 1];
    console.log("dog Family tree: ", families[families.length - 1]);
    this.setDogFamilyTree(dogTree);
  }
  getImagesForAnimal(id: number){
    const url = `${environment.animal_gallery}`
    return this._httpService.Get<GalleryImage[]>(url, { params: {animalId: id} } );
  }
}
