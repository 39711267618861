
const BaseUrl = "https://petpals.sys.redcode.se/API";

export const environment = {
  production: true, 
  BaseUrl: BaseUrl,
  ApiURL: BaseUrl,
  animalBaseURL: `${BaseUrl}/Animal`,
  animal_gallery: `${BaseUrl}/Animal/Gallery/Image`,
  messageHub: `${BaseUrl}/MessageHub`,
  notificationHub: `${BaseUrl}/NotificationHub`,
  defaultProfilePicture: `assets/images/defaultProfilePicture.png`,
  defaultDogPicture: `assets/images/defaultDogPicture.jpg`,
  authenticate_url: `${BaseUrl}/Auth`,
  resetPasswordRequest: `${BaseUrl}/Auth/ResetPasswordRequest`,
  resetPassword: `${BaseUrl}/Auth/ResetPassword`,
  refreshToken_url: `${BaseUrl}/Auth/Refresh`,
  filesave_location: `${BaseUrl}/File/Save`,
  register_url: `${BaseUrl}/User/Register`,
  update_user_url: `${BaseUrl}/User/Update`,
  get_user_url: `${BaseUrl}/User`,
  upload_files_url: `${BaseUrl}/File/Upload`,
  getMapMarkers: `${BaseUrl}/getMapMarkers`,
  getTotalLike: `${BaseUrl}/getMapMarkerLike`,
  validateEmail: `${BaseUrl}/Auth/Validate`,
  getUserData: `${BaseUrl}/User/Sok/Pnr`,
  userBaseURL: `${BaseUrl}/User`,
  registerDog: `${BaseUrl}/Animal`,
  updateUser: `${BaseUrl}/User/Update`,
  registerAnimal: `${BaseUrl}/Animal`,
  getAnimalFamilyTree: `${BaseUrl}/Animal/Tree`,
  getSubComments: `${BaseUrl}/Comment/SubComment`,
  messages: {
    addAndRemoveMessageRoomLike: `${BaseUrl}/addAndRemoveMessageRoomLike`,
    getMessagesRoomsUserIsIn: `${BaseUrl}/User/Rooms`,
    getMessageRoomsForUsers: `${BaseUrl}/getMessagesRoomsForUsers`,
    getAllOpenMessagesRooms: `${BaseUrl}/getAllOpenMessagesRooms`,
    getAllMessagesInRoom: `${BaseUrl}/getAllMessagesInRoom`,
    replyToMessage: `${BaseUrl}/Room/Reply`,
    createPublicMessage: `${BaseUrl}/createPublicMessage`,
    createPrivateMessage: `${BaseUrl}/createPrivateMessage`,
    EditPrivateMessageRoom: `${BaseUrl}/EditPrivateMessageRoom`,
    EditPublicMessageRoom: `${BaseUrl}/EditPublicMessageRoom`,
    EditPrivateMessage: `${BaseUrl}/EditPrivateMessage`,
    EditPublicMessage: `${BaseUrl}/EditPublicMessage`,
    UpdateMessageSeen: `${BaseUrl}/UpdateMessageSeen`,
  },
  toggleLike: `${BaseUrl}/Like/Toggle`,
  posts: {
    getAllPosts: `${BaseUrl}/User/Posts`,
    createPost: `${BaseUrl}/Post`,
  },
  postBaseURL: `${BaseUrl}/Post`,
  commentBaseURL: `${BaseUrl}/Post`,
  postCommentURL: `${BaseUrl}/Comment`,
  chatSuggestions: `${BaseUrl}/User/Suggestions`,
  createMessagesRoomForUsers: `${BaseUrl}/Room/CreateRoom`,
  createPrivateMessageRoom: `${BaseUrl}/createPrivateMessage`,
  getUserPosts: `${BaseUrl}/getPostsByUserId`,
  friendship: {
    getUserFriendships: `${BaseUrl}/User/Friends`,
    getSingleFriendship: `${BaseUrl}/Friendship`,
    createFriendship: `${BaseUrl}/Friendship`,
    updateFriendship: `${BaseUrl}/Friendship`,
    deleteFriendship: `${BaseUrl}/Friendship`,
  },
};
